import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink} from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { loadLocationWithMessage } from '../../../../actions/location'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { filter_patient_status } from '../variables/patient_1.js'
import Spinner from 'react-spinner-material'

class GroupList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          show_filter_status: 11,
          dataList: [],
          dataSelected: 0,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/get_group_list_by_category',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { category: 1, }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      }
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  convertDateFormat = (date) => {
    var buff1 = date.split(' ')
    var buff2 = buff1[0].split('-')
    return buff2[1]+'/'+buff2[2]+'/'+buff2[1]
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-5 columns headerPage1">
            Groups
          </div>
          <div className="small-12 medium-7 columns">
            <div className="ws_filter2">
            <NavLink to='/admin/worksheets/new_group' className="menu_list_active new_bttn new_p_btn">
              Add New Group
            </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>ID</th>
                    <th>Group Name</th>
                    <th>Clinicians</th>
                    <th>Assign Worksheets</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading1"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                item.id > 0 &&
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.clinicians}</td>
                  <td><a href={"/admin/worksheets/assign_worksheet_to_group/"+item.id}>Assign</a></td>
                  <td><a href={"/admin/worksheets/edit_group/"+item.id}>Edit</a></td>
                </tr>)}
              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={30} onChangePage={this.onChangePage} />
                  </td>
                </tr>}
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(GroupList)
