import React, { Component } from 'react';
import Cookies from 'js-cookie'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"

import { connect } from 'react-redux'
import AuthService from '../services/auth/auth.service.js'
import { loadAuthUser } from '../actions/auth'
import { loadLocation } from '../actions/location'
import RenderRoutes from '../routes/renderRoutes'

import NavBar from "../components/structure/NavBar";
import Header from "../components/structure/header";
import Footer from "../components/structure/footer";

class App extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      username: null,
      role: 0,
      message: '',
      checkUser: false,
    }
  }

  componentDidMount() {
    this.onReloadPages()
  }

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  updateUser (userObject) {
    this.setState(userObject)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadPages()
    this.props.history.push('/login')
    document.location.href = '/login'
  }

  checkExpireSession = async () => {

    var timeout_sec = this.props.expires
    var now = new Date().getTime()
    if(timeout_sec === undefined) {
      timeout_sec = now + (60 * 60 * 1000)
    }
    setTimeout(() => {
      if(now <= timeout_sec) {
        this.handleLogout()
      }
    }, timeout_sec - now - 1000)
  }

  renderElement() {
    var get_uri = window.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[1]
    var except_pages = ["", "home", "about", "introduction"]

    if(this.props.uid === null || this.props.uid > 0) { // render after loading user
      if(this.props.uid > 0 && (!except_pages.includes(get_uri))) {
        this.checkExpireSession()
        return (
          <React.Fragment>
            <div className="row wrapper member_container grid-x grid-margin-x" data-equalizer="user" data-equalize-on="medium" id="main_eq">
              <div className="small-12 medium-2 columns menu_left_bar show-for-medium no_padding_right" data-equalizer-watch="user">
                <NavBar loggedIn={this.state.loggedIn} />
              </div>
              <div className="small-12 medium-10 columns main_container" data-equalizer-watch="user">
                <RenderRoutes currRoute={this.props.route} />
              </div>
            </div>
          </React.Fragment>)
      } else {
        return (
          <React.Fragment>
            <RenderRoutes currRoute={this.props.route} />
          </React.Fragment>
        )
      }
    }
  }

  render() {
    return(
      <div>
        <Header />
        { this.renderElement() }
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    route: state.locationReducer.route,
    chkLoad: true,
    expires: state.authReducer.expires,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages() {
    dispatch(loadAuthUser())
    dispatch(loadLocation())
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App)
