import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../styles/assets/css/pages/dashboard.css'

import Graph2 from './graph2.jsx'
import Tracker from './tracker.jsx'
import ScheduledAssessments from './scheduled_assessments.jsx'
import TreatmentGoals from './treatment_goals.jsx'
import ToDoList from './to_do_list.jsx'
import UpcomingAppointment from './upcoming_appointment.jsx'
//import PatientContract from './patient_contract.jsx'
import Worksheet from './worksheets.jsx'
import Badges from './badges.jsx'

class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      update_graph: 0,
    }
  }

  handleUpdateTracker = (val) => {
    //console.log('handleUpdateTracker: '+val)
    if(val == 9) {
      this.setState({
        update_graph: Date.now(),
      })
    }
  }

  render() {
    //console.log(this.state)
    return (
    <div className="patient_dashboard" data-equalizer="dashboard">
      {this.props.authReducer.p_st == 6 &&
      <div>
        <Tracker onUpdateTracker={this.handleUpdateTracker} />
        { /* Right */ }
        <div className="small-12 medium-5 columns right_db no_padding_right" >
          <Badges />
          <ScheduledAssessments />
          <TreatmentGoals />
          <ToDoList />
          <UpcomingAppointment />
          <Worksheet />
        </div>
        <div className="small-12 columns box_border box_space_top1">
            <Graph2 pid={this.props.authReducer.uid} update_graph={this.state.update_graph} />
        </div>
      </div>}
      {this.props.authReducer.p_st != 6 &&
        <div className="not_available box_border">Dashboard will be available after finished initial intake appointment.</div>
      }
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
