import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form2.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Email from '@material-ui/icons/Email'
import WarningIcon from '@material-ui/icons/Warning'
import Spinner from 'react-spinner-material'
//import { makeStyles } from "@material-ui/core/styles";

class AssignWorksheetsGroup extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          loading: false,
          uid: 0,
          group_name: '',
          worksheet_list: {},
          worksheet_assigned: [],
          categoryList: [],
          categorySelected: 0,
      }
  }

  componentDidMount() {
    this.getAssmStatus()
  }

  getAssmStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/get_worksheet_list_assigned_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        gid: this.props.match.params.gid,
        uid: this.props.authReducer.uid,
        category: this.state.categorySelected,
      }
    })
    .then((result) => {
      console.log(result['data'])
      this.setState({
        worksheet_list: result['data'][0].value,
        worksheet_assigned: this.convAssigned(result['data'][1].value),
        categoryList: result['data'][2].value,
        group_name: result['data'][3].value[0].name,
        loading: true,
      })

    })
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/get_worksheet_list2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        category: this.state.categorySelected,
        gid: this.props.match.params.gid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        worksheet_list: result['data'],
        loading: true,
      })

    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  convAssigned = (data) => {
    var conv = []
    if(data.length > 0) {
      data.map(each => {
        conv.push(each.worksheet_id)
      })
    }
    return conv
  }

  handleSwitchChange = (e, wid) => {
    var val = 1
    if(e.target.checked === false) {
      val = 0
    }
    if(val == 1 && !this.state.worksheet_assigned.includes(wid)) {
      var buff = this.state.worksheet_assigned
      buff.push(wid)
      this.setState({
        worksheet_assigned: buff,
      })
    } else if(val == 0 && this.state.worksheet_assigned.includes(wid)) {
      var buff = this.state.worksheet_assigned
      const index = buff.indexOf(wid);
      if (index > -1) { // only splice array when item is found
        buff.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.setState({
        worksheet_assigned: buff,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/worksheets/update_worksheet_assigned_group',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        worksheet_assigned: this.state.worksheet_assigned,
        gid: this.props.match.params.gid,
      }
    })
    .then((result) => {
      if(result['data'] == this.props.match.params.gid) {
        this.setState({
          message: 'Assigned successfully!',
        })
        window.scrollTo(0, 0)
      } else {
        this.setState({
          message: 'Something went wrong. Please try again.',
        })
        window.scrollTo(0, 0)
      }
    })
  }

  render() {
    console.log(this.state)
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-8 columns headerPage1">
            Assign Worksheets to Group {this.state.group_name}
          </div>
          <div className="small-12 medium-4 columns">
            <div className="ws_filter2 w100p">
            <FormControl variant="outlined">
              <Select value={this.state.categorySelected} variant="outlined" name="category"
                label="" onChange={(e) => this.handleChange2(e, 'categorySelected')}>
                <MenuItem value="0">All</MenuItem>
                {this.state.categoryList.map(each =>
                  <MenuItem key={'c'+each.id} value={each.id}>{each.category_name}</MenuItem>
                )}
              </Select>
            </FormControl>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
          <div className="patient_edit">
            <div className="row">
              {!this.state.loading &&
              <div className="small-12 columns loading2">
                <Spinner color={"#bbbbbb"} radius={50}/>
              </div>}
              {this.state.loading &&
              <div className="small-12 columns ">
                { this.state.message !== '' &&
                  <div className="warning_msg8">
                    <div className="float_left1"><CheckCircleOutlineIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}
                <form id="make_appointment" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="small-12 columns top_space1">
                      <div className="bg1">
                        <div className="row">
                        {this.state.worksheet_list.map(each =>
                          <div className="small-12 medium-6 columns">
                            <Switch
                              checked={this.state.worksheet_assigned.includes(each.id)}
                              onChange={(e) => this.handleSwitchChange(e, each.id)}
                              color="primary" name="mbct"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />{each.name}
                          </div>)}
                          <div className="medium-6 columns show-for-medium">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                    <div className="small-12 medium-6 columns">
                      <a onClick={() => this.props.history.goBack()} className="go_back">Go Back</a>
                    </div>
                    <div className="small-12 medium-6 columns text_align_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Assign
                      </Button>
                    </div>
                  </div>
                </form>
              </div>}
            </div>
          </div>
          </div>
        </div>
      </div>)}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AssignWorksheetsGroup)
