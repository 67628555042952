import moment from 'moment'

export const calculateTimepoint1 = (get_timeline_start)=>{
  //console.log(get_timeline_start)
  var timeline_start = get_timeline_start
  var one_day = 60*60*24
  var diff_days = Math.floor((Math.floor(Date.now() / 1000) - timeline_start) / one_day)
  var current_week = Math.floor(diff_days / 7)
  var mod_days = diff_days % 7

  if(mod_days >= 5) {
    current_week += 1
  }

  var st_m_date = new Date((timeline_start - (5 * one_day))*1000)
  var current_month = monthDiff(st_m_date, new Date(Date.now()))
  var current_trimonth = Math.floor(current_month / 3)
  var current_year = Math.floor(current_month / 12)

  var now = moment()
  //console.log(now.format('MM-DD-YYYY'))
  var dateMoment = moment(new Date(timeline_start*1000))
  dateMoment.add(current_month, 'M')
  //console.log(dateMoment.format('MM-DD-YYYY'))
  var mod_months = now.diff(dateMoment, 'days') // count by hour-min-secounds

  var dateMomentY = moment(new Date(timeline_start*1000))
  dateMomentY.add(current_year, 'Y')
  var mod_years = now.diff(dateMomentY, 'days') // count by hour-min-secounds

  var newVal = {
    diff_days: diff_days,
    mod_days: mod_days,
    mod_months: mod_months,
    mod_years: mod_years,
    current_week: current_week,
    current_month: current_month,
    current_trimonth: current_trimonth,
    current_year: current_year,
  }
  //console.log('--- calculateTimepoint1 ---')
  //console.log(newVal)
  return(newVal)
}

export const calculateTimepoint2 = (get_timeline_start)=>{
  //console.log(get_timeline_start)
  var timeline_start = get_timeline_start.timeline_start
  var one_day = 60*60*24
  var diff_days = Math.floor((Math.floor(Date.now() / 1000) - timeline_start) / one_day)
  var current_week = Math.floor(diff_days / 7)
  var mod_days = diff_days % 7

  if(mod_days >= 5) {
    current_week += 1
  }

  var st_m_date = new Date((timeline_start - (5 * one_day))*1000)
  var current_month = monthDiff(st_m_date, new Date(Date.now()))
  var current_trimonth = Math.floor(current_month / 3)
  var current_year = Math.floor(current_month / 12)

  var now = moment()
  //console.log(now.format('MM-DD-YYYY'))
  var dateMoment = moment(new Date(timeline_start*1000))
  dateMoment.add(current_month, 'M')
  //console.log(dateMoment.format('MM-DD-YYYY'))
  var mod_months = now.diff(dateMoment, 'days') // count by hour-min-secounds

  var dateMomentY = moment(new Date(timeline_start*1000))
  dateMomentY.add(current_year, 'Y')
  var mod_years = now.diff(dateMomentY, 'days') // count by hour-min-secounds

  var newVal = {
    diff_days: diff_days,
    mod_days: mod_days,
    mod_months: mod_months,
    mod_years: mod_years,
    current_week: current_week,
    current_month: current_month,
    current_trimonth: current_trimonth,
    current_year: current_year,
  }
  //console.log('--- calculateTimepoint1 ---')
  //console.log(newVal)
  return(newVal)
}

export const calculateTimepoint3 = (get_timeline_start)=>{
  //console.log(get_timeline_start)
  var newVal = {
    diff_days: 0,
    mod_days: 0,
    mod_months: 0,
    mod_6m: 0,
    mod_years: 0,
    current_week: 0,
    current_month: 0,
    current_trimonth: 0,
    current_sixmonth: 0,
    current_year: 0,
  }
  if(get_timeline_start.timeline_start > 0) {
    var timeline_start = get_timeline_start.timeline_start
    var one_day = 60*60*24
    var diff_days = Math.floor((Math.floor(Date.now() / 1000) - timeline_start) / one_day)
    var current_week = Math.floor(diff_days / 7)
    var mod_days = diff_days % 7

    if(mod_days >= 5) {
      current_week += 1
    }

    var st_m_date = new Date((timeline_start - (5 * one_day))*1000)
    var current_month = monthDiff(st_m_date, new Date(Date.now()))
    var current_trimonth = Math.floor(current_month / 3)
    var current_sixmonth = Math.floor(current_month / 6)
    var current_year = Math.floor(current_month / 12)

    var now = moment()
    //console.log(now.format('MM-DD-YYYY'))
    var dateMoment = moment(new Date(timeline_start*1000))
    dateMoment.add(current_month, 'M')
    //console.log(dateMoment.format('MM-DD-YYYY'))
    var mod_months = now.diff(dateMoment, 'days') // count by hour-min-secounds

    var dateMoment6M = moment(new Date(timeline_start*1000))
    for(var i=0; i<6; i++) {
      dateMoment6M.add(current_sixmonth, 'M')
    }
    var mod_6m = now.diff(dateMoment6M, 'days')

    var dateMomentY = moment(new Date(timeline_start*1000))
    dateMomentY.add(current_year, 'Y')
    var mod_years = now.diff(dateMomentY, 'days') // count by hour-min-secounds

    newVal = {
      diff_days: diff_days,
      mod_days: mod_days,
      mod_months: mod_months,
      mod_6m: mod_6m,
      mod_years: mod_years,
      current_week: current_week,
      current_month: current_month,
      current_trimonth: current_trimonth,
      current_sixmonth: current_sixmonth,
      current_year: current_year,
    }
  }

  //console.log('--- calculateTimepoint1 ---')
  //console.log(newVal)
  return(newVal)
}

export const calculateTimepoint4 = (get_timeline_start)=>{
  //console.log(get_timeline_start)
  var timeline_start = get_timeline_start
  var one_day = 60*60*24
  var diff_days = Math.floor((Math.floor(Date.now() / 1000) - timeline_start) / one_day)
  var current_week = Math.floor(diff_days / 7)
  var mod_days = diff_days % 7

  if(mod_days >= 5) {
    current_week += 1
  }

  var st_m_date = new Date((timeline_start - (5 * one_day))*1000)
  var current_month = monthDiff(st_m_date, new Date(Date.now()))
  var current_trimonth = Math.floor(current_month / 3)
  var current_sixmonth = Math.floor(current_month / 6)
  var current_year = Math.floor(current_month / 12)

  var now = moment()
  //console.log(now.format('MM-DD-YYYY'))
  var dateMoment = moment(new Date(timeline_start*1000))
  dateMoment.add(current_month, 'M')
  //console.log(dateMoment.format('MM-DD-YYYY'))
  var mod_months = now.diff(dateMoment, 'days') // count by hour-min-secounds

  var dateMomentY = moment(new Date(timeline_start*1000))
  dateMomentY.add(current_year, 'Y')
  var mod_years = now.diff(dateMomentY, 'days') // count by hour-min-secounds

  var newVal = {
    diff_days: diff_days,
    mod_days: mod_days,
    mod_months: mod_months,
    mod_sixmonths: mod_months,
    mod_years: mod_years,
    current_week: current_week,
    current_month: current_month,
    current_sixmonth: current_sixmonth,
    current_trimonth: current_trimonth,
    current_year: current_year,
  }
  //console.log('--- calculateTimepoint1 ---')
  //console.log(newVal)
  return(newVal)
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    // edit: increment months if d2 comes later in its month than d1 in its month
    if (d2.getDate() >= d1.getDate())
        months++
    // end edit
    return months <= 0 ? 0 : months;
}
