import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { scid_yesno, scid_ynmb, scid_ynne, mini_not_completed_reason, scid_v1_q_a }
from '../variables/assessments/scid_v1.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperSCID_v1 from './stepper_SCID_v1'

class Scid1_v1 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          //start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'scid2_v1',
          completed_visit: 0,
          not_completed_reason: 0,
          not_completed_reason_other: '',
          date_visit: new Date().toISOString().substring(0, 10),
          rater: 0,
          rater_name: '',
          a1: 0,
          a2: 0,
          a3: 0,
          a4: 0,
          a5: 0,
          a6: 0,
          a7: 0,
          a8: 0,
          a9: 0,
          a9_other: "",
          a10: 0,
          a11: 0,
          a11_other: "",
          a12: 0,
          a13: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    // get Rater name //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_clinician_name',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      this.setState({
        rater: this.props.authReducer.uid,
        rater_name: result['data'].fullname,
      })
    })

    if(this.props.match.params.task == 'edit') {
      this.getData('edit')
    } else {
      // Initial MINI form to database //
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/chk_scid_v1_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          console.log('step: '+result['data'].scid_step)
          if(result['data'].scid_step === 'complete') {
            this.props.history.push('/admin/assessments/scid_v1_result/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else if(result['data'].scid_step !== 'scid1_v1') {
            //this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
            this.getData('new')
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      })
    }
  }

  getData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_scid_v1_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].end_1_timestamp > 0) {
        this.setState({
          completed_status: true,
          edit_status: get_edit_status,
          appt_id: this.props.match.params.appt_id,
          completed_visit: result['data'].completed_visit,
          not_completed_reason: result['data'].not_completed_reason,
          not_completed_reason_other: result['data'].not_completed_reason_other,
          date_visit: result['data'].date_visit,
          a1: result['data'].a1,
          a2: result['data'].a2,
          a3: result['data'].a3,
          a4: result['data'].a4,
          a5: result['data'].a5,
          a6: result['data'].a6,
          a7: result['data'].a7,
          a8: result['data'].a8,
          a9: result['data'].a9,
          a9_other: result['data'].a9_other,
          a10: result['data'].a10,
          a11: result['data'].a11,
          a11_other: result['data'].a11_other,
          a12: result['data'].a12,
          a13: result['data'].a13,
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    //this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    //this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    if(this.state.date_visit === '' || this.state.completed_visit === 0) {
      this.setState({
        message: 'Please select visiting answers ',
      })
      window.scrollTo(0, 0)
    } else {
      if(this.state.completed_visit === '2' && this.state.not_completed_reason === 0) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'admin/assessment/update_scid1_v1',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            pid: pid,
            timepoint: timepoint,
          }
        })
        .then((result) => {
          if(result['data'] === this.state.next_step) {
            this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
            this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          }
        })
      }
    }
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_scid1_v1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/assessments/scid_v1_result/'+pid+'/-1', 'Edit successfully!')
          this.props.history.push('/admin/assessments/scid_v1_result/'+pid+'/-1')
        }
      }
    )
  }

  render() {
    return (
      <div className="assm_mini box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            QuickSCID5: Intake Appointment
          </div>
          <StepperSCID_v1 activeStep={0} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>}
        {this.props.match.params.task == 'edit' &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            SCID Summary Sheet: Intake Appointment
          </div>
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        { //(this.state.appt_id > 0 || this.props.match.params.task == 'edit') &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1">
              <div className="row">
                <div className="small-12 medium-6 columns top_space2">
                  <span className="bold">Rater:</span> {this.state.rater_name}
                </div>
                <div className="small-12 medium-6 columns">
                  <span className="title1 bold">Date of Visit</span>
                  <span className="calendar1">
                    <TextField id="date_visit" label="" onChange={this.handleChange}
                  variant="outlined" type="date" value={this.state.date_visit}
                  disabled={this.state.completed_status && 'disabled'}
                  InputProps={{ inputProps: { name: "date_visit" }}} />
                  </span>
                </div>
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 medium-6 columns bold">
                  Was this form completed at this visit?
                </div>
                <div className="small-6 medium-6 columns">
                {scid_yesno.map(each =>
                  <div className="each_choice1" key={'comp'+each.label}>
                    <input type="radio" name="completed_visit"
                    checked={this.state.completed_visit == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    value={each.value} onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
                </div>
                { this.state.completed_visit == '2' &&
                <div>
                  <div className="small-12 columns">
                    <ArrowRightIcon /> Why wasn't the form completed at this visit?
                  </div>
                  {mini_not_completed_reason.map(each =>
                    <div className="small-12 columns" key={'reason'+each.label}>
                      <input type="radio" name="not_completed_reason" className="left_space1"
                      checked={this.state.not_completed_reason == each.value && 'checked'}
                      disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                      value={each.value} onChange={(e) => this.handleChange(e)}/>
                      <span>{each.label}</span>
                    </div>
                  )}
                  <div className="small-12 columns">
                    <ArrowRightIcon /> Other reason for not completing this form (specify)
                  </div>
                  <div className="small-12 columns">
                    <TextField label="" name="not_completed_reason_other"
                    onChange={(e) => this.handleChange(e)} variant="outlined"
                    value={this.state.not_completed_reason_other}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    InputProps={{ maxLength: 100 }}  />
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="row">

            <div className="small-12 columns box1 show_label">
              <div className="row show-for-medium bold">
                <div className="medium-9 columns">&nbsp;</div>
                {scid_ynne.map(each =>
                  each.value > 0 &&
                  <div className="medium-1 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>
              {scid_v1_q_a.map(eachQ =>
              <>
              <div className="row" >
                <div className="small-12 medium-9 columns">
                  A{eachQ.value}. {eachQ.label}
                  {(eachQ.value == 9 || eachQ.value == 11) &&
                    <TextField label="" name={"a"+eachQ.value+"_other"} value={this.state["a"+eachQ.value+"_other"]}
                    onChange={(e) => this.handleChange(e)} variant="outlined" className="tf1"
                    InputProps={{ maxLength: 100, placeholder: "Please specify" }} />}
                </div>
                {scid_ynne.map(each =>
                  each.value > 0 &&
                  <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                    <input type="radio" name={"a"+eachQ.value} value={each.value}
                    checked={this.state['a'+eachQ.value] == each.value && 'checked'}
                    disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
              </div>
              </>)}
            </div>

          <div className="small-12 columns align_right">
            {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('scid2_v1')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleEdit} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>}
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid1_v1)
