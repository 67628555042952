import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'
import '../../../../styles/assets/css/admin/form3.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../functions/pagination'
import { appt_status, appt_type_show } from '../../variables/patient'

class ContactLogs extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: []
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  getDataList = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip

    await axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_contact_logs_clinical_notes_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        ip: ip,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      }
    })
    .catch(function (error) {
      if (error.response) {
        //console.log(error.response)
      } else if (error.request) {
        //console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  render() {
    return (
      <div className="contact_logs">
        <div className="row">
          <div className="small-9 columns title4">
            Contact Logs
          </div>
          <div className="small-3 columns button_header2 bold">
            <NavLink to={'/admin/patient_contact_log/'+this.props.match.params.pid} className="menu_list_active">
              New Contact
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
                </div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Contact Date / Time</th>
                    <th>By</th>
                    <th>Reason for Contact</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map((item, index) =>
                <tr key={'d'+index} className={(item.reason_name == 'EPIC' && item.sub_type == 999) && "epic_txt1"} >
                  <td>{item.date_appt != 0 && 'Apt: '+item.conv_date}
                  {item.date_appt == 0 && item.conv_date}</td>
                  <td>{(item.cid == 1 && item.c_other_name) || (item.cid>1 && item.fullname_main)}</td>
                  <td>{item.reason_name}{item.reason==301 && '['+item.reason_other_txt+']'}</td>
                  <td>
                    {(item.sub_type == 1) &&
                      <span>
                        {this.props.authReducer.role == 3 &&
                        <span><Link to={"/admin/assessments/mini1/"+this.props.match.params.pid+'/'+item.id+"/-1"}>
                          MINI</Link><br /></span>}
                        {this.props.authReducer.role != 3 && '-' }
                      </span>}
                    {(item.sub_type != 1 && item.ct_id == null && item.sub_type != 999) &&
                      <Link to={"/admin/patient_log_outcome/"+this.props.match.params.pid+"/"+item.id+"/"+item.ct_id}>
                        Incomplete</Link>}
                    {(item.ct_id > 0 && item.sub_type != 999) &&
                      <Link to={"/admin/patient_log_outcome/"+this.props.match.params.pid+"/"+item.id+"/"+item.ct_id}>
                        Complete</Link>}
                    {(item.reason_name == 'EPIC' && item.sub_type == 999) &&
                    <Link to={"/admin/clinical_note/"+this.props.match.params.pid+"/"+item.link_id+"/"+item.note_type+"/"+item.ct_id+"/"+item.date_appt.substr(0, 10)}
                    className="epic_txt1" >
                      Details</Link>}
                  </td>
                </tr>
              )}
              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="6">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContactLogs)
