import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml, validateNoNegativeNumber } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import '../../../styles/assets/css/pages/form3.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { smoke_often, yes_no, days_per_week, reason_stopping, diagnosed,
  diabetes, hypertension, med_category, provider_often } from '../variables/medical_history.js'

class MedicalHistory extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          invalid: false,
          message: '',
          uid: 0,
          smoke: 0,
          smoke_often: 0,
          exercise: 0,
          exercise_days: 0,
          mindbody: 0,
          mindbody_days: 0,
          med_antidepressants: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_anticonvulsants: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_mood_stabilizers: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_antipsychotics: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_anxiolytics: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_anticholinergic: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_antihistamine: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_sedatives: [{ id:1, med: 0, taking: 0, dose: 0, taken: 0, reason: 0 }],
          med_other: [{ id:1, med: '', taking: 0, dose: 0, taken: 0, reason: 0 }],
          diagnosed: [],
          diagnosed_1: 0,
          diagnosed_2: 0,
          diagnosed_3: 0,
          diagnosed_4: 0,
          diagnosed_5: 0,
          diagnosed_6: 0,
          diagnosed_7: 0,
          diagnosed_8: 0,
          diagnosed_9: 0,
          diagnosed_10: 0,
          diagnosed_11: 0,
          diagnosed_12: 0,
          diagnosed_13: 0,
          diagnosed_14: 0,
          diagnosed_15: 0,
          diagnosed_16: 0,
          diagnosed_17: 0,
          diagnosed_18: 0,
          diagnosed_19: 0,
          diagnosed_20: 0,
          diagnosed_21: 0,
          diagnosed_22: 0,
          diagnosed_23: 0,
          diagnosed_brain_date: '',
          diagnosed_brain_text: '',
          diagnosed_diabetes: 0,
          diagnosed_hypertension: 0,
          diagnosed_cancer_txt: '',
          diagnosed_other: '',
          nonpsyciatric_med: [{ id:1, med: '', dose: 0 }],
          medication_list: [],
          pcp_name: '',
          pcp_contact: '',
          therapist_name: '',
          therapist_contact: '',
          therapist_often: 0,
          therapist_often_other: '',
          therapist_list: [{ id: 1, name: '', contact: '', therapist_often: 0, therapist_often_other: '', }],
          prescriber_name: '',
          prescriber_contact: '',
          prescriber_often: '',
          prescriber_often_other: '',
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'medical_history') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
        axios({
          method: 'post',
          url: connectNode + 'patient/registration/get_medication_list',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: { }
        })
        .then((list) => {
            this.setState({
              medication_list: list['data'],
            })
            }
        )
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    //console.log(e.target.name+": "+e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    })
    //console.log(e.target.name+": "+this.state.[e.target.name])
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }

  handleChange4 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }
  handleChange5_1 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = validateNoNegativeNumber(e.target.value)
    if(getStateVal[id][field] != null || getStateVal[id][field] != undefined) {
      this.setState({
        [name]: getStateVal
      })
    }
  }

  handleChange5_2 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    if(getStateVal[id][field] != null || getStateVal[id][field] != undefined) {
      this.setState({
        [name]: getStateVal
      })
    }
  }

  handleChange6 = (e, name, field) => {
    var index = 0
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      field.push(+e.target.value)

    } else {
      // or remove the value from the unchecked checkbox from the array
      index = field.indexOf(+e.target.value)
      field.splice(index, 1)
    }
    this.setState({
      [name]: field
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  validateMed = (name) => {
    var getMed = this.state[name]
    var st = true
    getMed.map(each => {
      if(each.med > 0 && each.taking === '1' &&
        (each.dose === 0 || each.dose === '0')) {
          st = false
      }
    })
    return st
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    if(this.state.pcp_name.trim().length == 0 || this.state.pcp_contact.trim().length == 0){
        this.setState({
          message: 'Please answer question 1',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else if(this.state.prescriber_name.trim().length == 0){
        this.setState({
          message: 'Please provide the name of your medication prescriber',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else if(this.state.smoke == 0 || (this.state.smoke == 1 && this.state.smoke_often == 0)){
        this.setState({
          message: 'Please select an answer for question 2',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else if(this.state.mindbody == 0
      || (this.state.mindbody == 1 && this.state.mindbody_days == 0)){
        this.setState({
          message: 'Please select an answer for question 3',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else {
      let st_med_valid = true
      var med_list = ['med_antidepressants', 'med_anticonvulsants', 'med_mood_stabilizers',
                      'med_antipsychotics', 'med_anxiolytics', 'med_anticholinergic',
                      'med_antihistamine', 'med_sedatives', 'med_other']
      med_list.map(med => {
        if(!this.validateMed(med)) {
          st_med_valid = this.validateMed(med)
        }
      })
      if(!st_med_valid) {
        this.setState({
          message: 'Please fill the number of dose',
          invalid: true,
        })
        window.scrollTo(0, 0)
      } else {
        this.setState({
          status_submit: false,
          message: '',
        })
        axios({
          method: 'post',
          url: connectNode + 'patient/registration/update_medical_history',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
          }
        })
        .then((result) => {
            if(result['data'] === 'psychiatric_history') {
              this.props.onLoadLocation('/member/psychiatric_history')
              this.props.history.push('/member/psychiatric_history')
            }

          }
        )
      }
    }
  }

  appendInputMedication = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, med: 0, taking: 0, dose: 0, taken: 0, reason: 0,
        }])
      }
    ))
  }

  appendInputMedication2 = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, med: '', taking: 0, dose: 0, taken: 0, reason: 0,
        }])
      }
    ))
  }

  appendInputMedication3 = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, med: '', dose: 0
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  appendInputTherapist = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, name: '', contact: '',
        }])
      }
    ))
  }

  render() {
    //console.log(this.state)
    return (
      <div className="medical_history box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Medical History
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={8}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box2_1">
            <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="general_information">
                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">1. Please provide the name and contact information of your primary care provider (PCP).</div>
                    <div className="small-12 columns q_pcp">
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Name:
                        </div>
                        <div className="small-12 medium-9 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "pcp_name", maxLength: 50 }}} name="pcp_name"
                        onChange={(e) => this.handleChange4(e, 'pcp_name')} />
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Email/Phone:
                        </div>
                        <div className="small-12 medium-9 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "pcp_contact", maxLength: 100 }}} name="pcp_contact"
                        onChange={(e) => this.handleChange4(e, 'pcp_contact')} />
                        </div>
                      </div>
                    </div>
                    <div className="small-12 columns title_topic1 top_space1">Please provide name and contact information of your medication prescriber if not your PCP (psychiatrist, psychopharmacologist or psychiatric nurse practitioner).</div>
                    <div className="small-12 columns q_pcp">
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Name:
                        </div>
                        <div className="small-12 medium-9 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "prescriber_name", maxLength: 50 }}} name="prescriber_name"
                        onChange={(e) => this.handleChange4(e, 'prescriber_name')} />
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Email/Phone:
                        </div>
                        <div className="small-12 medium-9 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "prescriber_contact", maxLength: 100 }}} name="prescriber_contact"
                        onChange={(e) => this.handleChange4(e, 'prescriber_contact')} />
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Frequency of visits:
                        </div>
                        <div className="small-12 medium-9 columns">
                        <div className="w95p">
                          <FormControl variant="outlined">
                            <Select value={this.state.prescriber_often} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'prescriber_often', 'val')}>
                                {provider_often.map(each =>
                                  <MenuItem key={"po"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                        </div>
                      </div>
                      {this.state.prescriber_often == 4 &&
                      <div className="row">
                        <div className="medium-3 columns bold top_space1 align_right">
                          &nbsp;
                        </div>
                        <div className="small-12 medium-9 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "prescriber_often_other", maxLength: 100, placeholder: 'Specify frequency', }}}
                        name="prescriber_often_other"
                        onChange={(e) => this.handleChange4(e, 'prescriber_often_other')} />
                        </div>
                      </div>}
                    </div>
                    <div className="small-12 columns title_topic1 top_space1">If you see a therapist(s) outside of FITT-BD, please provide name and contact information.</div>
                    {this.state.therapist_list.map((val, index) =>
                    <div className="small-12 columns q_pcp">
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Name:
                        </div>
                        <div className="small-12 medium-8 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { maxLength: 50 }}}
                        onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'name')}
                        variant="outlined" value={this.state.therapist_list[index].name} />
                        </div>
                        <div className="small-12 medium-1 columns">&nbsp;</div>
                      </div>
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Email/Phone:
                        </div>
                        <div className="small-12 medium-8 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { maxLength: 100 }}}
                        onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'contact')}
                        variant="outlined" value={this.state.therapist_list[index].contact} />
                        </div>
                        <div className="small-12 medium-1 columns align_left">
                        {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('therapist_list', val.id, this.state.therapist_list) } />}
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="small-12 medium-3 columns bold top_space1 align_right">
                          Frequency of visits:
                        </div>
                        <div className="small-12 medium-8 columns">
                        <div className="w95p top_space4">
                          <FormControl variant="outlined">
                            <Select value={this.state.therapist_list[index].therapist_often} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'therapist_often')}>
                                {provider_often.map(each =>
                                  <MenuItem key={"po"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                        </div>
                        <div className="medium-1 columns">&nbsp;</div>
                      </div>
                      {this.state.therapist_list[index].therapist_often == 4 &&
                      <div className="row">
                        <div className="medium-3 columns bold top_space1 align_right">
                          &nbsp;
                        </div>
                        <div className="small-12 medium-8 columns">
                        <TextField label="" variant="outlined"
                        InputProps={{ inputProps: { name: "therapist_often_other", maxLength: 100, placeholder: 'Specify frequency', }}}
                        name="prescriber_often_other"
                        onChange={(e) => this.handleChange5_2(e, 'therapist_list', index, 'therapist_often_other')} />
                        </div>
                        <div className="medium-1 columns">&nbsp;</div>
                      </div>}
                    </div>)}
                    <div className="small-12 columns top_space1">
                      <button type="button" className="addInput1"
                         onClick={ () => this.appendInputTherapist('therapist_list', this.state.therapist_list) }>
                          Add Therapist
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">2. Have you smoked tobacco within the past year?</div>
                    <div className="row">
                      <div className="small-12 columns frm_space1 q1_smoke">
                        {yes_no.map(each =>
                          <div className="each_radio1" key={"sm"+each.value}>
                          <input type="radio" name="smoke"
                                key={"smoke"+each.value} value={each.value}
                                checked={this.state.smoke == each.value}
                                onChange={(e) => this.handleChange2(e)}/>
                                {each.label}</div>
                        )}
                      </div>
                    </div>
                    {this.state.smoke == 1 &&
                    <div className="row top_space">
                      <div className="small-12 medium-6 columns frm_space1">
                        <div className="title_space1"><ArrowRightIcon /> How often?</div>
                        <div className="frm_space2 left_space1">
                          <FormControl variant="outlined">
                            <Select value={this.state.smoke_often} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'smoke_often', 'val')}>
                                {smoke_often.map(each =>
                                  <MenuItem key={"smoke_often"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">3.	Do you currently practice any mind-body techniques (e.g., meditation, relaxation, repetitive prayer, yoga)?</div>
                    <div className="row">
                      <div className="small-12 columns frm_space1 q1_smoke">
                        {yes_no.map(each =>
                          <div className="each_radio1" key={"mindbody"+each.value}>
                            <input type="radio" name="mindbody" value={each.value}
                                checked={this.state.mindbody == each.value}
                                onChange={(e) => this.handleChange2(e)}/>
                                {each.label}</div>
                        )}
                      </div>
                    </div>
                    {this.state.mindbody == 1 &&
                    <div className="row top_space">
                      <div className="small-12 columns float_left2">
                        <div className="title_space1"><ArrowRightIcon /> How many days of the week do you practice any of these techniques?</div>
                      </div>
                      <div className="small-12 medium-6 columns float_left2">
                        <div className="frm_space2 left_space1">
                          <FormControl variant="outlined">
                            <Select value={this.state.mindbody_days} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'mindbody_days', 'val')}>
                                {days_per_week.map(each =>
                                  <MenuItem key={"mindbody_days"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1 q4">
                    <div className="title_topic1">4. Please indicate the psychiatric medications you have taken in the past or are currently taking:</div>
                    <div className="row">
                      <div className="small-12 columns">
                        {/* Category 1 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Antidepressants
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_antidepressants.map((val, index) =>
                              <div className="row each_m_set" key={"m_antidep"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_antidepressants', val.id, this.state.med_antidepressants) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_antidepressants[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_antidepressants', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "antidepressants").value] ?? []).map(each =>
                                        <MenuItem key={"antidepressants"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_antidepressants[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_depressants_taking"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_antidepressants[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_antidepressants', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_antidepressants[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_antidepressants', index, 'dose')}
                                        variant="outlined" value={this.state.med_antidepressants[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_antidepressants[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_depressants_3m"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_antidepressants[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_antidepressants', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_antidepressants[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_antidepressants[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_antidepressants', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_depressants_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}

                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_antidepressants', this.state.med_antidepressants) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 2 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Anticonvulsants / Mood Stabilizers
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">

                            {this.state.med_anticonvulsants.map((val, index) =>
                              <div className="row each_m_set" key={"m_anticon"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_anticonvulsants', val.id, this.state.med_anticonvulsants) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_anticonvulsants[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_anticonvulsants', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "anticonvulsants").value] ?? []).map(each =>
                                        <MenuItem key={"anticonvulsants"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_anticonvulsants[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_anticon_taking"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_anticonvulsants[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anticonvulsants', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_anticonvulsants[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_anticonvulsants', index, 'dose')}
                                        variant="outlined" value={this.state.med_anticonvulsants[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_anticonvulsants[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_depressants_3m"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_anticonvulsants[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anticonvulsants', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_anticonvulsants[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_anticonvulsants[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_anticonvulsants', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_conv_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_anticonvulsants', this.state.med_antidepressants) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 4 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Antipsychotics
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_antipsychotics.map((val, index) =>
                              <div className="row each_m_set" key={"m_antipsy"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_antipsychotics', val.id, this.state.med_antipsychotics) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_antipsychotics[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_antipsychotics', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "antipsychotic").value] ?? []).map(each =>
                                        <MenuItem key={"antipsychotic"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_antipsychotics[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_anticon_taking"+index+each.value} >
                                            <input type="radio"
                                            value={each.value}
                                            checked={this.state.med_antipsychotics[index].taking == each.value}
                                            onChange={(e) => this.handleChange5(e, 'med_antipsychotics', index, 'taking')}/>
                                            {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_antipsychotics[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_antipsychotics', index, 'dose')}
                                        variant="outlined" value={this.state.med_antipsychotics[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_antipsychotics[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_psychotic"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_antipsychotics[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_antipsychotics', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_antipsychotics[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_antipsychotics[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_antipsychotics', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_conv_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_antipsychotics', this.state.med_antipsychotics) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 5 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Anxiolytics
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_anxiolytics.map((val, index) =>
                              <div className="row each_m_set" key={"m_anxio"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_anxiolytics', val.id, this.state.med_anxiolytics) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_anxiolytics[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_anxiolytics', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "anxiolytics").value] ?? []).map(each =>
                                        <MenuItem key={"anxiolytics"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_anxiolytics[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_anxio_taking"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_anxiolytics[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anxiolytics', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_anxiolytics[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_anxiolytics', index, 'dose')}
                                        variant="outlined" value={this.state.med_anxiolytics[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_anxiolytics[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1"><input type="radio"
                                                key={"at_anxio"+index+each.value} value={each.value}
                                                checked={this.state.med_anxiolytics[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anxiolytics', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_anxiolytics[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_anxiolytics[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_anxiolytics', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_anxio_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_anxiolytics', this.state.med_anxiolytics) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 6 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Anticholinergic
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_anticholinergic.map((val, index) =>
                              <div className="row each_m_set" key={"m_anxio"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_anticholinergic', val.id, this.state.med_anticholinergic) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_anticholinergic[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_anticholinergic', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "anticholinergic").value] ?? []).map(each =>
                                        <MenuItem key={"anticholinergic"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_anticholinergic[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_antiocho_taking"+index+each.value} >
                                            <input type="radio"
                                                value={each.value}
                                                checked={this.state.med_anticholinergic[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anticholinergic', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_anticholinergic[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_anticholinergic', index, 'dose')}
                                        variant="outlined" value={this.state.med_anticholinergic[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_anticholinergic[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1"><input type="radio"
                                                key={"at_anticho"+index+each.value} value={each.value}
                                                checked={this.state.med_anticholinergic[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_anticholinergic', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_anticholinergic[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_anticholinergic[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_anticholinergic', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_antiocho_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_anticholinergic', this.state.med_anticholinergic) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 7 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Antihistamine
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_antihistamine.map((val, index) =>
                              <div className="row each_m_set" key={"m_mood"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_antihistamine', val.id, this.state.med_antihistamine) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_antihistamine[index].med} variant="outlined"
                                      onChange={(e) => this.handleChange5(e, 'med_antihistamine', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "antihistamine").value] ?? []).map(each =>
                                        <MenuItem key={"antihistamine"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_antihistamine[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1"><input type="radio"
                                                key={"at_his_taking"+index+each.value} value={each.value}
                                                checked={this.state.med_antihistamine[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_antihistamine', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_antihistamine[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_antihistamine', index, 'dose')}
                                        variant="outlined" value={this.state.med_antihistamine[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_antihistamine[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1"><input type="radio"
                                                key={"at_his_3m"+index+each.value} value={each.value}
                                                checked={this.state.med_antihistamine[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_antihistamine', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_antihistamine[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_antihistamine[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_antihistamine', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_his_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}
                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 8 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Sedatives
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_sedatives.map((val, index) =>
                              <div className="row each_m_set" key={"m_sed"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns float_left">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_sedatives', val.id, this.state.med_sedatives) } />}
                                  <FormControl variant="outlined">
                                    <Select value={this.state.med_sedatives[index].med} variant="outlined"
                                      label="Select medication"
                                      onChange={(e) => this.handleChange5(e, 'med_sedatives', index, 'med')}>
                                      {(this.state.medication_list[med_category.find(y => y.label === "sedatives").value] ?? []).map(each =>
                                        <MenuItem key={"sedatives"+each.med_id} value={each.med_id}>{each.med_name}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { this.state.med_sedatives[index].med > 0 &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_sed_taking"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_sedatives[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_sedatives', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_sedatives[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_sedatives', index, 'dose')}
                                        variant="outlined" value={this.state.med_sedatives[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_sedatives[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_sed"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_sedatives[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_sedatives', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_sedatives[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_sedatives[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_sedatives', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_sed_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication('med_sedatives', this.state.med_sedatives) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                        {/* Category 9 */}
                        <div className="q4_category">
                          <div className="small-12 columns frm_space1 title_topic2">
                            Other
                          </div>
                          <div className="small-12 columns ">
                            <div className="frm_space2">
                            {this.state.med_other.map((val, index) =>
                              <div className="row each_m_set" key={"m_ot"+val.id} >
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                                <div className="small-12 medium-4 columns med_name">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('med_other', val.id, this.state.med_other) } />}
                                  <TextField label="Medication name" onChange={(e) => this.handleChange3(e, 'med_other', index, 'med')}
                                  variant="outlined"  inputProps={{maxLength: 60}} value={this.state.med_other[index].med}/>
                                </div>
                                { this.state.med_other[index].med != '' &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        Are you currently taking this medication?
                                      </div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_ot_taking"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_other[index].taking == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_other', index, 'taking')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  { this.state.med_other[index].taking == 1 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">
                                        What is the recommended total daily dose?
                                      </div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'med_other', index, 'dose')}
                                        variant="outlined" value={this.state.med_other[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>}
                                  { this.state.med_other[index].taking == 2 &&
                                  <div className="row top_space2">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Taken for at least 3 months?</div>
                                      <div className="med_q1_ans">
                                        {yes_no.map(each =>
                                          <div className="each_radio1" key={"at_sed"+index+each.value} >
                                            <input type="radio" value={each.value}
                                                checked={this.state.med_other[index].taken == each.value}
                                                onChange={(e) => this.handleChange5(e, 'med_other', index, 'taken')}/>
                                                {each.label}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>}
                                  { this.state.med_other[index].taking == 2 &&
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Reason for stopping</div>
                                      <div className="med_q1_ans q4_reason">
                                      <FormControl variant="outlined">
                                        <Select value={this.state.med_other[index].reason} variant="outlined"
                                          onChange={(e) => this.handleChange5(e, 'med_other', index, 'reason')}>
                                          {reason_stopping.map(each =>
                                            <MenuItem key={"at_ot_rs"+index+each.value} value={each.value}>{each.label}</MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                      </div>
                                    </div>
                                  </div>}

                                </div>}
                              </div>
                            )} {/* End row */}
                            </div>
                            <div className="small-12 columns sep_line1">&nbsp;</div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication2('med_other', this.state.med_other) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1 q4">
                    <div className="title_topic1">5. Please list any non-psychiatric medications you are currently taking:</div>
                    <div className="row">
                      <div className="small-12 columns">
                        {/* Category 1 */}
                        <div className="q4_category">
                          <div className="small-12 columns ">
                            <div className="frm_space3">
                            {this.state.nonpsyciatric_med.map((val, index) =>
                              <div className="row each_m_set" key={"m_non"+val.id} >
                                <div className="small-12 medium-4 columns med_name">
                                  {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('nonpsyciatric_med', val.id, this.state.nonpsyciatric_med) } />}
                                  <TextField label="Medication name" onChange={(e) => this.handleChange3(e, 'nonpsyciatric_med', index, 'med')}
                                  variant="outlined"  inputProps={{maxLength: 60}} value={this.state.nonpsyciatric_med[index].med}/>
                                </div>
                                { this.state.nonpsyciatric_med[index].med != '' &&
                                <div className="small-12 medium-8 columns q4_each">
                                  <div className="row top_space">
                                    <div className="small-12 columns">
                                      <div className="med_q1">Total daily dosage:</div>
                                      <div className="med_q1_ans">
                                        <TextField label="" type="number"
                                        onChange={(e) => this.handleChange5_1(e, 'nonpsyciatric_med', index, 'dose')}
                                        variant="outlined" value={this.state.nonpsyciatric_med[index].dose}
                                        InputProps={{ maxLength: 10 }}  />
                                      </div>
                                      <div className="med_q1_detail">mg</div>
                                    </div>
                                  </div>
                                </div>}
                                <div className="small-12 columns sep_line1">&nbsp;</div>
                              </div>
                            )} {/* End row */}
                            </div>
                          </div>
                          <div className="small-12 columns ">
                            <button type="button" className="addInput1"
                               onClick={ () => this.appendInputMedication3('nonpsyciatric_med', this.state.nonpsyciatric_med) }>
                                Add Another Medication
                            </button>
                          </div>
                        </div>{/*End Category*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">6. Have you ever been diagnosed with the following?</div>
                    <div className="row">
                      <div className="small-12 columns frm_space1">

                        <div className="each_checkbox">
                          <div className="row">
                            <div className="small-12 columns">
                              <input type="checkbox" name="diagnosed_1"
                                value={diagnosed[0].value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {diagnosed[0].label}
                            </div>
                            { this.state.diagnosed_1 == 1 &&
                            <div>
                              <div className="small-12 columns">
                                <div className="med_q1_detail">
                                  <ArrowRightIcon /> Approximate date of most recent injury
                                </div>
                                <div className="med_q1_detail">
                                  <TextField name="diagnosed_brain_date"
                                  onChange={(e) => this.handleChange(e)}
                                  variant="outlined"  inputProps={{maxLength: 40}} />
                                </div>
                              </div>
                              <div className="small-12 columns">
                                <div className="med_q1_detail">
                                  <ArrowRightIcon /> Please describe
                                </div>
                                <div className="med_q1_detail2">
                                  <TextField name="diagnosed_brain_text"
                                  onChange={(e) => this.handleChange(e)}
                                  variant="outlined"  inputProps={{maxLength: 50}} />
                                </div>
                              </div>
                            </div>
                            }
                          </div>
                        </div>

                        <div className="each_checkbox">
                        <div className="row">
                          <div className="small-12 columns">
                            <input type="checkbox" name="diagnosed_2"
                              value={diagnosed[1].value}
                              onChange={(e) => this.handleChange7(e)}/>
                              {diagnosed[1].label}
                          </div>
                          { this.state.diagnosed_2 == 1 &&
                            <div className="small-12 columns">
                              <div className="med_q1_detail"><ArrowRightIcon /> Type of diabetes</div>
                              <div className="med_q1_detail">
                                <FormControl variant="outlined">
                                  <Select value={this.state.diagnosed_diabetes} variant="outlined" className="diabetes"
                                    onChange={(e) => this.handleChange4(e, 'diagnosed_diabetes')}>
                                      {diabetes.map(each =>
                                        <MenuItem key={"diabetes"+each.value} value={each.value}>{each.label}</MenuItem>
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>}
                          </div>
                        </div>

                        <div className="each_checkbox">
                          <div className="row">
                            <div className="small-12 columns">
                              <input type="checkbox" name="diagnosed_3"
                                value={diagnosed[2].value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {diagnosed[2].label}
                            </div>
                            { this.state.diagnosed_3 == 1 &&
                            <div className="small-12 columns">
                              <div className="med_q1_detail"><ArrowRightIcon /> Are you currently being treated for high blood pressure with medication(s)?</div>
                              <div className="med_q1_detail">
                                <FormControl variant="outlined">
                                  <Select value={this.state.diagnosed_hypertension} variant="outlined" className="diabetes"
                                    onChange={(e) => this.handleChange4(e, 'diagnosed_hypertension')}>
                                      {hypertension.map(each =>
                                        <MenuItem key={"hypertension"+each.value} value={each.value}>{each.label}</MenuItem>
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>}
                          </div>
                        </div>

                        {diagnosed.map(each =>
                          each.value > 3 && each.value <15 &&
                          <div className="each_checkbox" id={"dg"+each.value} key={"diagnosed"+each.value} >
                            <div className="med_q1_detail">
                              <input type="checkbox" name={"diagnosed_"+each.value}
                                value={each.value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {each.label}
                            </div>
                          </div>
                        )}

                        <div className="each_checkbox">
                          <div className="row">
                            <div className="small-12 columns">
                              <input type="checkbox" name="diagnosed_15"
                                value={diagnosed[14].value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {diagnosed[14].label}
                            </div>
                            { this.state.diagnosed_15 == 1 &&
                              <div className="small-12 columns">
                                <div className="small-12 columns">
                                  <ArrowRightIcon /> Please describe
                                </div>
                                <div className="small-12 columns">
                                <TextField name="diagnosed_cancer_txt"
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"  inputProps={{maxLength: 200}} />
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                        {diagnosed.map(each =>
                          each.value > 15 && each.value <23 &&
                          <div className="each_checkbox" id={"dg"+each.value} key={"diagnosed"+each.value} >
                            <div className="med_q1_detail">
                              <input type="checkbox" name={"diagnosed_"+each.value}
                                value={each.value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {each.label}
                            </div>
                          </div>
                        )}

                        <div className="each_checkbox">
                          <div className="row">
                            <div className="small-12 columns">
                              <input type="checkbox" name="diagnosed_23"
                                value={diagnosed[22].value}
                                onChange={(e) => this.handleChange7(e)}/>
                                {diagnosed[22].label}
                            </div>
                            { this.state.diagnosed_23 == 1 &&
                              <div className="small-12 columns">
                                <div className="small-12 columns">
                                  <ArrowRightIcon /> Please write down your medical condition
                                </div>
                                <div className="small-12 columns">
                                <TextField name="diagnosed_other"
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"  inputProps={{maxLength: 200}} />
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              <div className="row">
                <div className="small-12 columns align_right">
                {this.state.status_submit &&
                  <Button variant="contained" color="primary" disableElevation
                   onClick={this.handleSubmit} type="button" className="submit_1">
                    Submit
                  </Button>}
                {!this.state.status_submit &&
                  <Button variant="contained" disabled
                   onClick={this.handleSubmit} type="button" className="submit_1">
                    Submit
                  </Button>}
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MedicalHistory)
