import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, NavLink } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PatientHead3 from "../../patient/patient_head3"
import ReactHtmlParser from 'react-html-parser'

class WorksheetsAssessments extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          name: '',
          selectedFile: '',
          category: 0,
          categoryList: [],
          dataList: [],
          editMode: false,
      }
  }

  componentDidMount() {
    //this.getData()
  }

  render() {
    //console.log(this.state)
    return (
      <div className="admin_log_outcome box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Worksheet/Assessment Assignment
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_inner2 bold">
              <ul >
                <li className="top_space1"><a href="/admin/worksheets/worksheet_list">Individual Worksheet</a></li>
                <li className="top_space1"><a href="/admin/worksheets/group_list">Group Worksheet Assignment</a></li>
                {/*<li className="top_space1"><a href="/admin/worksheets/self_guide_list">Self-Guided Programs</a></li>*/}
                <li className="top_space1"><a href="/admin/self_stigma/self_stigma_list">Self Stigma Assessments</a></li>
              </ul>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(WorksheetsAssessments)
