import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { updateLocation } from '../../actions/location'
//import Foundation from 'foundation-sites';
import { AppBar, Toolbar } from '@material-ui/core'
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DashboardIcon from '@material-ui/icons/Dashboard'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import DescriptionIcon from '@material-ui/icons/Description'
import SearchIcon from '@material-ui/icons/Search'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import SpaIcon from '@material-ui/icons/Spa'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import { CgMail } from 'react-icons/cg'
//import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SettingsIcon from '@material-ui/icons/Settings'
import { NavDrawer } from "./NavDrawer"

//import { BASE_URL } from '../../const/global'
//import PATIENTS_ROUTES from "../../routes/patientRoutes";
import '../../styles/assets/css/foundation-icons/foundation-icons.css'

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false
    }
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  onReloadLogoutPages = () => {
    //console.log('Navbar onReloadPages')
    this.props.onLoadLogoutPages()
  }

  onReloadPages = (route) => {
    //console.log('Navbar onReloadPages')
    this.props.onLoadPages(route)
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadLogoutPages()
  }

  handlePage(route) {
    this.onReloadPages(route)
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div className="main_menu">
      <AppBar position="static">
        <Toolbar>
          <div className="show-for-medium">
          { this.props.role >= 1 && this.props.role <= 3 &&
            <div className="menu_list">
            <NavLink to='/admin/patient_search' activeClassName="menu_list_active">
              <SearchIcon />
              <div className="menu_list_name">SEARCH</div>
            </NavLink>
          </div> }
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/patient_list' activeClassName="menu_list_active">
                <ListAltIcon />
                <div className="menu_list_name">PATIENTS</div>
              </NavLink>
            </div> }
            { /*this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/wait_list' activeClassName="menu_list_active">
                <HourglassEmptyIcon />
                <div className="menu_list_name">WAIT LIST</div>
              </NavLink>
            </div>*/ }
            {/*this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/self_stigma/self_stigma_list' activeClassName="menu_list_active">
                <InsertDriveFileIcon />
                <div className="menu_list_name">SELF STIGMA</div>
              </NavLink>
            </div>*/}
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/worksheets/worksheets_assessments' activeClassName="menu_list_active">
                <InsertDriveFileIcon />
                <div className="menu_list_name">WORKSHEETS/<br />ASSESSMENTS</div>
              </NavLink>
            </div> }
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/reports/cgi_bd' activeClassName="menu_list_active">
                <AssessmentIcon />
                <div className="menu_list_name">REPORTS</div>
              </NavLink>
            </div>}
            { /*this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <NavLink to='/admin/self_stigma/worksheet_list' activeClassName="menu_list_active">
                <NoteAddIcon />
                <div className="menu_list_name">WORKSHEETS</div>
              </NavLink>
            </div>*/}
            { this.props.role == 5 && this.props.p_st == 6 &&
            <div className="menu_list">
              <NavLink to='/dashboard' activeClassName="menu_list_active" onClick={this.handlePage('dashboard')}>
                <DashboardIcon />
                <div className="menu_list_name">DASHBOARD</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && this.props.p_st == 6 &&
            <div className="menu_list">
              <NavLink to="/member/assessment_list" activeClassName="menu_list_active">
                <AssessmentIcon />
                <div className="menu_list_name">ASSESSMENTS</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && this.props.p_st == 6 &&
            <div className="menu_list">
              <NavLink to='/member/patient_contract_list' activeClassName="menu_list_active">
                <DescriptionIcon />
                <div className="menu_list_name">PATIENT CONTRACT</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && this.props.p_st == 6 &&
            <div className="menu_list">
              <NavLink to='/member/appointment_list' activeClassName="menu_list_active">
                <CalendarTodayIcon />
                <div className="menu_list_name">APPOINTMENTS</div>
              </NavLink>
            </div>}
            { this.props.role == 5 && this.props.p_st == 6 &&
            <div className="menu_list">
              <NavLink to='/member/consent_forms' activeClassName="menu_list_active">
                <DescriptionIcon />
                <div className="menu_list_name">CONSENT FORMS</div>
              </NavLink>
            </div>}
            { ((this.props.role == 5 && this.props.p_st == 6 && this.props.course_id == 1)
              || (this.props.course_id == 1)) &&
            <div className="menu_list">
              <NavLink to="/member/intervention/1/main" activeClassName="menu_list_active">
                <SpaIcon />
                <div className="menu_list_name">MINDFULNESS PROGRAM</div>
              </NavLink>
            </div>}
            { this.props.role == 5 &&
            <div className="menu_list">
              <a href='mailto:fitt-bd@mgh.harvard.edu?subject=Contact FITT-BD Team' activeClassName="menu_list_active">
                <CgMail />
                <div className="menu_list_name">CONTACT<br />FITT-BD TEAM</div>
              </a>
            </div>}
            { this.props.role >= 1 && this.props.role <= 3 &&
              <div className="menu_list">
              <a href='/clinician_user_guide.pdf' activeClassName="menu_list_active">
                <MenuBookIcon />
                <div className="menu_list_name">USER GUIDE</div>
              </a>
            </div> }
            <div className="menu_list margin-bttom-1">
              <NavLink to="/user/settings" activeClassName="menu_list_active">
                <SettingsIcon />
                <div className="menu_list_name">SETTINGS</div>
              </NavLink>
            </div>
            { /*<div className="menu_list">
              <NavLink to="/logout" activeClassName="menu_list_active" onClick={ () => this.handleLogout() }>
                <ExitToAppIcon />
              </NavLink>
            </div>*/ }
          </div>
        </Toolbar>
      </AppBar>

        <NavDrawer
          drawerOpened={this.state.drawerOpened}
          toggleDrawer={this.toggleDrawer}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('NavBar STATE: ', state)
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    b_step: state.authReducer.b_step,
    course_id: state.authReducer.course_id,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadLogoutPages() {
    //console.log('NavBar mapDispatchToProps')
    dispatch(logoutUser())
  },
  onLoadPages(route) {
    //console.log('NavBar updateLocation: '+route)
    //dispatch(updateLocation(route))
    dispatch({
      type: 'UPDATE_LOCATION',
      payload: {route: route},
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)

//export default NavBar;

/*<div className="menu_list">Dashboard</div>*/
